<!--解决方案  消毒供应服务-->
<template>
    <div>
      <div class="boxBanner">
          <Nav nums='3' isColor='#fff'/>
        <div class="homebanner">
           <Video :bannerArr='coverBanner'/>
         </div>
        </div>
        <div class="main">
            <div class="part1">
                <div class="title">
                    <SectionTitle title="Disinfection supply" :with-highlighted-tail="false" :with-dot="false"/>
                    <SectionTitle title="center"/>
                    <SectionSubTitle title="消毒供应中心"/>
                </div>
                <div class="box_hlt banner1_main">
                    <div class="banner1 boxflex1" @mousewheel="rollScroll($event)">
                        <el-carousel height="28.4375vw"
                        direction="vertical"
                        :autoplay="false"
                        trigger="hover"
                        ref="carousel"
                        indicator-position='outside'
                        @mousewheel="isMousewheel($event)">
                            <el-carousel-item v-for="(item,index) in supplyImg" :key="index">
                             <img :src="item.images" alt="" style="width:100%;height:100%">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="banner2">
                        <el-carousel height="150px" direction="vertical">
                            <el-carousel-item v-for="(item,index) in supplyImg" :key="index">
                                <img :src="item.images" alt="" style="width:100%;height:100%">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="text2">
                        消毒供应中心是医院消毒灭菌系统中具备清洗、消毒、灭菌功能的核心部门，是无菌物品供应周转的物流中心，是临床医疗服务的重要保障科室，其工作质量直接影响医疗护理质量和患者安全，
                        它虽不直接服务病人，但服务于临床各个科室，担负着全院一次性无菌物品的管理和发放，
                        更重要的是它担负着全院可重复使用物品的回收、清洗、消毒、灭菌与发放工作，所以常被称为医院的‘肝脏’。
                    </div>
                </div>
            </div>
            <div class="part2">
                <div class="box_h_side_c"  ref="getNum">
                    <div class="item box_scc">
                        <div class="box_hcb">
                            <span class="number">
                                <animate-number from="0" :to="number.bundle" duration="1000" ref="nums1"></animate-number>
                            </span>
                            <span class="unit">万把</span>
                        </div>
                        <div>年处理器械</div>
                    </div>
                    <div class="item box_scc">
                        <div class="box_hcb">
                            <span class="number">
                                <animate-number from="0" :to="number.km" duration="1000" ref="nums2"></animate-number>
                            </span>
                            <span class="unit">公里</span>
                        </div>
                        <div>辐射半径</div>
                    </div>
                    <div class="item box_scc">
                        <div class="box_hcb">
                            <span class="number">
                                <animate-number from="0" :to="number.open" duration="1000" ref="nums3"></animate-number>
                            </span>
                            <span class="unit">张</span>
                        </div>
                        <div>区域内床位数</div>
                    </div>
                </div>
                <div class="info box_hcc breakall">
                    企业按照WS310.2016标准，在医院内部或外部，自行投资、建设并管理运营的消毒供应中心，为一定区域内的多家卫生机构提供符合国家规范及质量标准的第三方、集中化的医疗清洗、消毒、灭菌及物流配送服务，并纳入到卫生行政主管部门的日常监管。
                </div>
            </div>
            <div class="part3">
                <div class="title">
                    <SectionTitle title="Development" :with-highlighted-tail="false" :with-dot="false"/>
                    <SectionTitle title="prospects"/>
                    <SectionSubTitle title="发展前景"/>
                </div>
                <div class="part3_body">
                   <div class="box">
                    <div class="item1" v-for="(item,index) in prospectsArr" :key="index">
                        <div class="title1">{{item.title}}</div>
                        <div class="info">{{item.content}}</div>
                    </div>
                   </div>
                </div>
            </div>
            <div class="part4">
                <div class="title">
                     <SectionTitle title="Scope" :with-highlighted-tail="false" :with-dot="false"/>
                    <SectionTitle title="of business"/>
                    <SectionSubTitle title="业务范围"/>
                </div>
                <div class="part4_main box_hlt box_lines">
                    <div class="item box_hlc" v-for="(item,index) in businessArr" :key="index" :style="'background: url(' + item.images + ') no-repeat center center;background-size: 100% 100%'">
                        <p class="box_hcc">{{item.depict}}</p>
                    </div>
                </div>
            </div>
            <div class="part5">
                <div class="title">
                     <SectionTitle title="Value-added" :with-highlighted-tail="false" :with-dot="false"/>
                    <SectionTitle title="services"/>
                    <SectionSubTitle title="增值服务"/>
                </div>
                <div class="part5_body">
                    <div class="item1">加急手术</div>
                    <div class="item2">器械变更</div>
                    <div class="item3">物流配送</div>
                    <div class="item4">数据管理</div>
                    <div class="item5">器械租赁</div>
                </div>
            </div>
            <div class="part6">
                <div class="title">
                    <SectionTitle title="Production" :with-highlighted-tail="false" :with-dot="false"/>
                    <SectionTitle title="process"/>
                    <SectionSubTitle title="生产流程"/>
                </div>
                <div class="part6_body">
                    <div class="text3">老肯医疗第三方消毒供应中心所有生产流程和操作规范严格按照WS310标准，并通过追溯管理系统和点自质控系统加以控制，避免人为操作出现的器械丢失、损坏、物流延误、质量缺失等问题，在有效提升效率的同时，确保高质量的服务水平和及时性的服务能力。</div>
                    <div class="quan box_hcc">
                        <div class="nquan">
                            <div class="nquan_icon1"></div>
                            <div class="nquan_icon2"></div>
                        </div>
                        <img class="icon1" src="../assets/solution/arrow.png"/>
                        <img class="icon2" src="../assets/solution/arrow.png"/>
                        <img class="icon3" src="../assets/solution/arrow.png"/>
                        <img class="icon4" src="../assets/solution/arrow.png"/>
                        <img class="icon5" src="../assets/solution/arrow.png"/>
                        <img class="icon6" src="../assets/solution/arrow.png"/>
                        <img class="icon7" src="../assets/solution/arrow.png"/>
                        <img class="icon8" src="../assets/solution/arrow.png"/>
                        <img class="icon9" src="../assets/solution/arrow.png"/>
                        <img class="icon10" src="../assets/solution/arrow.png"/>
                    </div>
                </div>
            </div>
            <div class="part7">
                <div class="title">
                    <SectionTitle title="Reality Scene"/>
                    <SectionSubTitle title="车间实景"/>
                </div>
                <div class="part7_body" id="plantBox">
                    <swiper class="prize-swiper-wrapper PC" :options="swiperHistory">
                        <swiper-slide class="prize-image" v-for="(s, index) in scen" :key='index'>
                            <div class="item item1 box_hrc" v-for="(item,index) in s" :key="index" :style="'background: url(' + item.images + ') no-repeat center center;background-size: 100% 100%'">
                                <div class="btn1 box_hcc">{{item.name}}</div>
                                <div class="mb box_hrc">
                                    <router-link :to="{path:'/plant',query:{id:item.id}}">查看更多</router-link>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <div class="MT">
                        <div class="item item1 box_hrc" v-for="(item,index) in placeArr" :key="index" :style="'background: url(' + item.images + ') no-repeat center center;background-size: 100% 100%'">
                        <div class="btn1 box_hcc">{{item.name}}</div>
                        <div class="mb box_hrc">
                            <router-link :to="{path:'/plant',query:{id:item.id}}">查看更多</router-link>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div class="part8">
                <div class="title">
                    <SectionTitle title="Running mode"/>
                    <SectionSubTitle title="运行模式"/>
                </div>
                <div class="part8_body">
                    <div class="title1">项目运行三大模<span>式.</span></div>
                    <div class="box_h_space_c">
                        <div class="item_body box_hcc">
                            <div class="item box_hcc">院内托管</div>
                        </div>
                        <div class="item_body box_hcc">
                            <div class="item box_hcc">院内新建</div>
                        </div>
                        <div class="item_body box_hcc">
                            <div class="item box_hcc">院外新建</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part9">
                <div class="title">
                    <SectionTitle title="Quality system"/>
                    <SectionSubTitle title="质量体系"/>
                </div>
                <div class="part9_body">
                    <div class="title1">完善的风险管理系统</div>
                    <div class="box_hlt info">
                        <div class="left">
                            <div>
                                <p class="box_hcc">CSSD执行国际统一标准</p>
                                <span>ISO 9001:2008 ISO 13485:2003</span>
                            </div>
                            <div>
                                <p class="box_hcc">CSSD执行国内统一标准</p>
                                <span>WS 310.1-2016</span>
                                <span>医院消毒供应中心 第1部分：管理规范</span>
                                <span>WS 310.2-2016</span>
                                <span>医院消毒供应中心 第2部分：清洗消毒及灭菌操作规范</span>
                                <span>WS 310.3-2016</span>
                                <span>医院消毒供应中心 第2部分：清洗消毒及灭菌效果监测标准</span>
                            </div>
                        </div>
                        <div class="moreBox">
                            <div class="center">
                            <div class="box_scc" style="padding-left:0.5vw,padding-right:0.5vw">
                                <p>全球性的</p>
                                <p>质量管理</p>
                            </div>
                            <div class="box_hcc">
                                <p>过程控制</p>
                            </div>
                            <div class="box_hcc">
                                <p>可追溯性</p>
                            </div>
                          </div>
                          <div class="right">
                            <div class="box_scc">
                                <p>遵循统一的标准并完全符合工厂所在国的相关标准</p>
                            </div>
                            <div class="box_scc">
                                <p>整个生产流程具备相当数量的质量控制点</p>
                                <p>严格的交叉感染控制体系</p>
                                <p>严格的员工培训体系</p>
                            </div>
                            <div class="box_scc">
                                <p>通过TrackOne信息追溯系统实现完整的流程追溯整个生产流程具备相当数量的质量控制节点</p>
                                <p>完整的化学和生物监测分析机制</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part10">
                <div class="title">
                    <SectionTitle title="Logistics system"/>
                    <SectionSubTitle title="物流体系"/>
                </div>
                <div class="part10_body">
                    <div class="title1">物流作为每个器械消毒灭菌周期的起始和结束，其重要性不言而喻。我们提供了完善的解决方案，分析了各种可能出现问题的环节，并配备相应对策，有效降低客户风险，保障消毒供应的同时提高器械周转率。</div>
                    <div class="box_h_side_t">
                        <div class="item">
                            <div class="box_hcc"><img src="../assets/solution/part10_icon1.png" style="width:13.5625vw;height:7.91666vw"></div>
                            <p>物流车</p>
                            <span>在无菌物品的收发过程中，公司配备专用的物流车和有经验的运输人员按时到医院各运送点收发相关物品</span>
                        </div>
                        <div class="item">
                            <div class="box_hcc"><img src="../assets/solution/part10_icon2.png" style="width:13.3333vw;height:7.020833vw"></div>
                            <p>装载车和转运箱</p>
                            <span>公司定制了专用于医疗灭菌产品运送的符合联合国卫生组织Un3291标准的装载车和转运箱</span>
                        </div>
                        <div class="item">
                            <div class="box_hcc"><img src="../assets/solution/part10_icon3.png" style="width:9.21875vw;height:9.791666vw"></div>
                            <p>物流线路设计</p>
                            <span>结合多年来为各类型医疗机构的消毒外包服务经验，公司致力于根据实际需求为多个客户整合定制高效的物流解决方案，在及时保障消毒供应的同时，避免了资源浪费，有效降低物流成本</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Floor/>
    </div>
</template>

<script>
	import Nav from './nav'
    import Floor from './floor.vue'
    import { imgHttp, request } from '../apis/http'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import NavMixin from '@/mixins/nav-minxin'
    import Video from '../components/video.vue'

	export default {
		title: 'test',
		components: {
			Nav,
            Floor,
            Swiper,
            SwiperSlide,
            Video
		},
        mixins: [
            NavMixin
        ],
        inject:['reload'],
		data() {
			return {
                supplyImg:[],
				list: [],
                scen:[],
                businessArr:[],
                timeOut:null,
                number:{ bundle:1500, km:200, open:16000 }, // 消毒供应中心数据
                changeNum: true,
                coverBanner:[],
                bannerVideo:true,
                bannerImg:[],
                prospectsArr:[],
                swiperHistory:{
                    slidesPerView: 1,
                    spaceBetween: 30,
                    centeredSlides: true,
                    loop: true,
                    autoplay: true
                },
                placeArr:[]
			}
		},
        beforeMount() {
            this.bannerList()
            this.business()
            this.peality()
            this.bigBanner()
            this.prospects()
            scrollTo(0, 0)
        },
    methods: {
    // 消毒供应中心
    async bannerList(){
        const res = await request({
            url:'/api/index/banner'
        })
        const newBanner = res.data.data.map(i => {
            i.images = imgHttp + i.images
            return i
        })
        this.supplyImg = newBanner
    },
    // 业务范围
    async business(){
        const res = await request({
            url:'/api/index/business'
        })
        const businessCont = res.data.data.map(i => {
            i.images = imgHttp + i.images
            return i
        })
        this.businessArr = businessCont
    },
    // 车间实况
    async peality(){
        const res = await request({
            url:'/api/index/plantlist'
        })
        this.placeArr = res.data.data
        const pealityCont = res.data.data.map((i, n) => {
            i.images = imgHttp + i.images
            return i
        })
        const pageArr = []
        pealityCont.forEach((el, index) => {
            const page = Math.floor(index / 3)
            if (!pageArr[page]){
                pageArr[page] = []
            }
            pageArr[page].push(el)
        })
        this.scen = pageArr
    },
    // 发展前景
    async prospects () {
      const res = await request({
        url: '/api/index/course?type=2'
      })
      this.prospectsArr = res.data.data
   },
    // 大banner
    async bigBanner () {
      const res = await request({
        url: '/api/index/cover?type=1'
      })
      const bannerNewArr = res.data.data.map(i => {
        if (i.images !== ''){
            i.images = i.images.split(',').map(r => {
                r = imgHttp + r
                return r
            })
          }
          if (i.videos !== ''){
              i.videos = i.videos.split(',').map(r => {
              r = imgHttp + r
              return r
          })
          }
          i.arr = [...i.videos, ...i.images]
        return i
      })
        this.coverBanner = bannerNewArr[0].arr
   },
    rollScroll(event) {
    event.preventDefault()
     const _that = this
     const scrollVal = event.wheelDelta || event.detail
     if (!_that.timeOut) {
       _that.timeOut = setTimeout(() => {
         _that.timeOut = null
         scrollVal > 0
           ? _that.$refs.carousel.prev()
           : _that.$refs.carousel.next()
       }, 300)
      } else {
     }
   },
   locate(){
       window.location.href = '#plantBox'
   }
  },
  mounted() {
      const _this = this
     if (this.$route.query.active){
      this.locate()
     }
     window.addEventListener('scroll', this.item = function(){
        const getHeight = _this.$refs.getNum.getBoundingClientRect().top
        //  console.log('容器的高度', _this.$refs.getNum.offsetHeight)
        //  console.log('滚动条的距离', document.documentElement.scrollTop)
        //  console.log('窗口的高度', document.documentElement.clientHeight)
        //  console.log('容器到页面顶部距离', _this.$refs.getNum.getBoundingClientRect().top)
        //  console.log('容器到页面顶部距离', document.getElementById('getNum').offsetTop)
         if (getHeight < document.documentElement.clientHeight - _this.$refs.getNum.offsetHeight){
              if (_this.changeNum){
                _this.changeNum = false
                _this.$refs.nums1.start()
                _this.$refs.nums2.start()
                _this.$refs.nums3.start()
                setTimeout(function(){
                 _this.changeNum = true
               }, 8000)
             }
         }
     })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.item)
  }
}
</script>
<style lang="less">
    @import './fn.less';
    @import "../style/box.css";
    body {
        background: #fff;
    }
    .MT{
        display: none;
    }
    .boxBanner {
            width: 100%;
            height: 46vw;
            position: relative;
        .homebanner{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        .el-carousel .el-carousel__indicators{
        flex-direction: row;
      }
        }
    }
    .swiper-container{
        margin-top: 1.8vw;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        height: 30vw;
        padding-top: 0;
        .swiper-wrapper{
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            box-sizing: content-box;
            transition-property: transform;
        }
    }
    .main {
        width: 100%;
        min-width: 1200px;
        margin: 0 auto;
    }

    .part1{
        width: 100%;
        padding-top: 7.8125vw;
        box-sizing: border-box;
        .vw(padding,150,110,0);
        .banner1_main{
            width: 100%; padding-top: 2.6145833vw;
            .text2{
                width: 23.4375vw; padding-right: 6.30208333vw;
                height: 30.4375vw;
                margin-left: 2.08333vw;
                font-size: 1.04166vw;
                color: #6C7388;
                line-height: 2.1875vw;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                overflow: hidden;
            }
            .banner1{
                width: 100%; height: 30.4375vw;
            }
            .banner2{
                display: none;
            }
        }
    }

    .part2{
        width: 100%; height: 21.40625vw;
        margin: 10.8125vw 0 0 0; position: relative;
        overflow: hidden;
        background: url('../assets/solution/ABOUT.png') no-repeat center center;
        background-size: 100% 100%;
        padding-top: 5vw; box-sizing: border-box;
        .box_h_side_c{
            padding:0 11.45833vw; box-sizing: border-box;
            font-size: 1.3541666vw; color: #00538D;
            .number{
                font-size: 3.854166vw; font-weight: bold;
            }
            .unit{
                position: relative; top: -0.9375vw;
                padding-left: 0.520833vw;
            }
        }
        .info{
            width: 100%;
            padding:0 18.75vw;
            box-sizing: border-box;
            text-align: center;
            margin-top: 2.7604166vw;
            font-size: 1.04166vw;
            line-height: 2.1875vw;
        }
    }

    .part3{
        box-sizing: border-box;
        .vw(padding,200,110,0);
        .part3_body{
            padding:5.00625vw 5.229166vw;
            width: 100%;
            box-sizing: border-box;
            .box{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .item1,
        .item2{
            width: 24.395833vw;
            padding: 2.03125vw; box-sizing: border-box;
            background:#F5F5F6;
            border-radius: 1.145833vw;
            margin-bottom:1.5625vw;
            color:#6C7388; font-size: 1.04166vw;
            line-height: 1.6666vw;
            transition: all .5s linear;
            &:hover{
                background:url('../assets/solution/part3_bg_hover.png') no-repeat center center;
                background-size: 100% 100%;
                color:#fff;
            }
        }
        .item2{
            width: 34.375vw;
        }
        .title1{
            font-size: 1.5625vw; font-weight: bold;
            padding-bottom: 1.354166vw;
        }
    }

    .part4{
        background: linear-gradient(#FEFEFF,#E4F3FF);
        box-sizing: border-box;
        .vw(padding,100,110,0);
        .part4_main{
            width: 100%;
            padding: 6.25vw 4.729166vw;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .item{
                width: 15.04375vw; height: 20.5625vw; margin-left: 0.416666vw;
                margin-bottom: 4.5625vw;
                transition: all .4s linear;
                p{
                    width: 7.8125vw; height: 100%;
                    background: #2F54A7;
                    -webkit-writing-mode: vertical-lr;
                    -ms-writing-mode: bt-lr;
                    writing-mode: vertical-lr;
                    font-size: 1.04166vw; color: #fff;
                    line-height: 1.5625vw;
                    text-align: center;
                    overflow: hidden;
                }
                &:hover{
                background-size: 110% 110%!important;
                transition: background-size .4s linear;
                }
            }
            .item:nth-child(2n){
                p{
                    background: rgba(47, 84, 167, .5);
                }
            }
        }
    }

    .part5{
        background: linear-gradient(#E4F3FF,#ffffff);
        box-sizing: border-box;
        .vw(padding,50,110,0);
        .part5_body{
            width: 100%; height: 35.59375vw;
            position: relative;
            div{
                width: 14.791666vw; height: 15.2604167vw;
                background: url('../assets/solution/arc_bg.png') no-repeat center center;
                background-size: 100% 100%;
                position: absolute; text-align: center;
                font-size: 1.8229166vw;
                font-weight: bold;
                color: #FFFFFF;
                text-shadow: 0 7px 6px rgba(71, 164, 255, 0.4);
                padding-top: 4.0104166vw;
                padding-left: 0.520833vw;
                box-sizing: border-box;
            }
            .item1{
                left:12.0208vw; top: 15.21875vw;
                animation:animateFn1 2s linear .3s infinite alternate;
            }
            .item2{
                left:26.7604vw; top: 3.86458333vw;
                animation:animateFn1 2s linear .6s infinite alternate;
            }
            .item3{
                left:39.9895833vw; top: 15.21875vw;
                animation:animateFn1 2s linear .9s infinite alternate;
            }
            .item4{
                left:53.010416vw; top: 3.86458333vw;
                animation:animateFn1 2s linear 1.2s infinite alternate;
            }
            .item5{
                left:68.16666vw; top: 15.21875vw;
                animation:animateFn1 2s linear 1.5s infinite alternate;
            }
        }
    }

    .part6{
        background: linear-gradient(#ffffff,#E4F3FF,#ffffff);
        box-sizing: border-box;
        .vw(padding,100,110,0);
        height: 100%;
        .part6_body{
            padding: 2.479166vw 3.6770833vw 0 3.6770833vw;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            display: flex;
            position: relative;
            .text3{
                width: 20.9375vw; height: 30vw; font-size: 1.04166vw; color: #6C7388;
                line-height: 2.1875vw; padding-top: 6.16666vw;
            }
        }
        .quan{
            width: 35.729166vw; height: 35.729166vw;
            background: url('../assets/solution/arc_icon.png') no-repeat center center;
            background-size: 100% 100%;
            margin: 0 auto; position: relative;
            position: absolute;
            .vw(top,-100);
            .vw(left,720);
            img{
                width: 2.5vw;
                opacity: 0;
            }
            .icon1{
                position: absolute; left:18.5625vw; top:2.0675vw;
                animation:animateFn2 2s linear .2s infinite;
            }
            .icon2{
                position: absolute; left:27.50625vw; top:5.28125vw;
                transform: rotate(30deg);
                animation:animateFn2 2s linear .4s infinite;
            }
            .icon3{
                position: absolute; left:31.3125vw; top:14.21875vw;
                transform: rotate(70deg);
                animation:animateFn2 2s linear .6s infinite;
            }
            .icon4{
                position: absolute; left:29.96875vw; top:23.5395833vw;
                transform: rotate(100deg);
                animation:animateFn2 2s linear .8s infinite;
            }
            .icon5{
                position: absolute; left:23.3333vw; top:30.833333vw;
                transform: rotate(130deg);
                animation:animateFn2 2s linear 1s infinite;
            }
            .icon6{
                position: absolute; left:14.38958333vw; top:32.3958333vw;
                transform: rotate(170deg);
                animation:animateFn2 2s linear 1.2s infinite;
            }
            .icon7{
                position: absolute; left:6.375vw; top:27.916666vw;
                transform: rotate(210deg);
                animation:animateFn2 2s linear 1.4s infinite;
            }
            .icon8{
                position: absolute; left:2.4541666vw; top:19.9875vw;
                transform: rotate(244deg);
                animation:animateFn2 2s linear 1.6s infinite;
            }
            .icon9{
                position: absolute; left:3.104166vw; top:10.8vw;
                transform: rotate(285deg);
                animation:animateFn2 2s linear 1.8s infinite;
            }
            .icon10{
                position: absolute; left:9.40625vw; top:3.9375vw;
                transform: rotate(310deg);
                animation:animateFn2 2s linear 2s infinite;
            }
        }
        .nquan{
            position: relative;
            width: 18.1770833vw; height: 18.1770833vw;
            background: url('../assets/solution/arc_icon1.png') no-repeat center center;
            background-size: 100% 100%;
        }
        .nquan_icon1{
            width: 4.28125vw; height: 7.0395833vw;
            position: absolute; right: -2.68125vw; top: 1.7479166vw;
            background: url('../assets/solution/part6_arrow.png') no-repeat right bottom;
            background-size:cover;
        }
        .nquan_icon2{
            width: 4.28125vw; height: 7.0395833vw;
            position: absolute; left: -2.67708333vw; top: 3.66875vw;
            background: url('../assets/solution/part6_arrow.png') no-repeat left top;
            background-size:cover;
        }
    }

    .part7{
        box-sizing: border-box;
        .vw(padding,50,110,0);
        .part7_body{
            // width: 100%;
            height: 30.3020833vw;
            margin: 4.6666vw 5.729166vw 13.4375vw 5.729166vw;
            background: url('../assets/solution/part7_bg.jpg') no-repeat left center;
            background-size: auto 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-left: 10vw;
            padding-right: 10vw;
            box-sizing: border-box;
            .item{
                width: 50.6666vw; height: 8.1041667vw;
                margin-bottom: 1.30208vw; position: relative;
                transition: all .3s linear;
                .vw(margin-left,100);
                .btn1{
                    width: 6.15625vw; height: 6.15625vw;
                    border-radius: 1vw;
                    background: RGBA(41, 158, 220, .8);
                    font-size: 1.4479166vw; color: #fff;
                    position: absolute; left: -4.291666vw; top: 50%;
                    margin-top: -3.1041666vw;
                    text-align: center;
                    &:after{
                        content: '';
                        width: 1.979166vw; height: 3.3854166vw; display: block;
                        background: url('../assets/solution/part7_arrow.png') no-repeat center center;
                        background-size: 60% 60%;
                        position: absolute; right: -3.166666vw; top: 50%;
                        margin-top: -1.66666vw;
                    }
                }
                .mb{
                    width: 28.645833vw; height: 8.1041667vw;
                    background: url('../assets/solution/part_7mb.png') no-repeat center center;
                    background-size: 100% 100%;
                    padding-right: 4.729166vw; box-sizing: border-box;
                    a{
                        display: block;
                        width: 5.025vw; height: 1.63125vw;
                        background: #00538D;
                        border-radius: 1.04166vw;
                        font-size: 0.84166vw;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 1.63125vw;
                        text-decoration:none;
                        &:link,&:visited{
                            color: #fff;
                        }
                        &:hover{
                            opacity: .7;
                        }
                    }
                }
                &:hover{
                    background-size: 110% 110%!important;
                }
            }
            .item:nth-child(3){
                margin-bottom: 0;
            }
        }
    }

    .part8{
        box-sizing: border-box;
        .vw(padding,0,110,0);
        .part8_body{
            width: 100%; padding-top: 4.8125vw;
            .title1{
                width: 100%; text-align: center;
                font-size: 2.395833vw; color: #171B1D;
                font-weight: bold;
                span{
                    color: #00538D;
                }
            }
            .box_h_space_c{
                width: 70.5vw; height: 30.5520833vw; margin: 0 auto;
                padding-bottom: 4.16666vw; box-sizing: border-box;
                .item_body{
                    width: 33.33333%; height: 100%;
                }
                .item{
                    width: 15.46875vw; height: 15.46875vw;
                    background: linear-gradient(-45deg, rgba(0, 126, 255, 0.69), rgba(118, 199, 255, 0.69));
                    border-radius: 1.04166vw;
                    font-size: 2.395833vw;
                    font-weight: 800;
                    transition: all .3s linear;
                    color: #FFFFFF;
                    &:hover{
                        transform: scale(1.12);
                        background: #FFFFFF;
                        border: 10px solid #BDE3FF;
                        box-shadow: 0.625vw 4.270833vw 2.65625vw 0px rgba(177, 220, 255, 0.38);
                        color: #5BADFF;
                    }
                }
            }
        }
    }

    .part9{
        box-sizing: border-box;
        .vw(padding,100,110,0);
        .part9_body{
            width: 100%; height:31.1458222vw; margin: 3.6458333vw 0 13.020833vw 0;
            box-sizing: border-box;
            background: url('../assets/solution/part9_bg.jpg') no-repeat right center;
            background-size: auto 100%;
            .title1{
                padding-top: 2.1979166vw;
                padding-bottom: 5.5791666vw;
                font-size: 2.0958333vw;
                font-weight: 800;
                color: #00538D;
            }
            .info{
                width: 59.4895833vw; margin: 0 auto;
            }
            .left{
                width: 27.53125vw;
                div{
                    box-sizing: border-box;
                    width: 100%; background: rgba(0, 132, 224, .23);
                    border-radius: 1.04166vw; margin-bottom: 2.73125vw;
                    padding: 1.304166vw 1.1979166vw 0.9020833vw 1.1979166vw; position: relative;
                }
                p{
                    position: absolute; left:50%; margin-left: -9.6875vw;
                    top: -1.25vw;
                    width: 19.3229vw;
                    height: 2.4479vw;
                    background: #0084E0;
                    border-radius: 1.25vw;
                    font-size: 1.00208vw;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 1.8229166vw;
                }
                span{
                    display: block;
                    line-height: 1.3229166vw;
                    font-size: 0.94166vw;
                    color: #00538D;
                }
            }
            .moreBox{
                display: flex;
            .center{
                margin: 0 0.6770833vw;
                div{
                    width: 100%; height: 4.2666vw;
                    background: rgba(0, 132, 224, .23);
                    border-radius: 1.04166vw;
                    margin-bottom: 1.70208vw;
                    .vw(padding-left,5);
                    .vw(padding-right,5);
                }
                p{
                    font-size: 0.84166vw;
                    font-weight: 400;
                    color: #00538D;
                    line-height: 1.4229166vw;
                }
            }
            .right{
                width: 25.8333vw;
                margin-left: 0.5vw;
                div{
                    padding: 0.88979166vw;
                    width: 100%;
                    background: rgba(0, 132, 224, .23);
                    border-radius: 1.04166vw;
                    margin-bottom: 0.9020833vw;
                }
                p{
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 0.84166vw;
                    font-weight: 400;
                    color: #00538D;
                    line-height: 1.32291667vw;
                }
            }

            }
        }
    }

    .part10{
        box-sizing: border-box;
        .vw(padding,00,110,0);
        .part10_body{
            width: 63.28125vw; margin: 0 auto;
            box-sizing: border-box;
            padding: 3.3229166vw 0 7.1875vw 0;
            .title1{
                font-size: 1.00208vw;
                font-weight: 400;
                color: #00538D;
                line-height: 1.71875vw;
                padding-bottom: 3.5625vw;
            }
            .item{
                width: 18.28125vw;
                div{
                    width: 100%; height: 10.7291667vw;
                    background: rgba(0, 132, 224, .2);
                    border-radius: 1.71875vw;
                    margin-bottom: 0.625vw;
                }
                p{
                    width: 100%; height: 3.3333vw;
                    margin-bottom: 1.1354166vw;
                    text-align: center;
                    line-height: 3.3333vw;
                    background: rgba(0, 132, 224, .2);
                    border-radius: 1.71875vw;
                    font-size: 1.00208vw;
                    font-weight: 800;
                    color: #00538D;
                }
                span{
                    font-size: 1.00208vw;
                    color: #6F768B;
                    line-height: 1.71875vw;
                }
            }
        }
    }

    @keyframes animateFn1 {
        0% {
            transform: translateY(-1.5625vw);
        }
        100% {
            transform: translateY(1.5625vw);
        }
    }

    @keyframes animateFn2 {
        0% {
            opacity: 0;
        }
        30%{
            opacity: 1;
        }
        60%{
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    .el-carousel{
        width: 100%;
        display: flex;
        .el-carousel__container{
            flex: 1;
        }
        .el-carousel__indicators{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 2.08333vw;
        }
    }
    .el-carousel img{
        width: 100%;
    }
.el-carousel__indicators li .el-carousel__button{
      background-color: #00538D!important;;
  }
  .el-carousel--vertical .el-carousel__indicator--vertical .el-carousel__button{
      width: 0.520833vw!important;
      height: 0.520833vw!important;
      border-radius: 50%;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .swiper-container{
      margin-left: 1vw;
  }
@media screen and (max-width: 980px) {
    .main {
        min-width: 0;
    }
    .boxBanner {
        .homebanner{
            margin-top: 5vw;
        }
    }
    .MT{
        display: block;
    }
    .PC{
        display: none;
    }
    .part1{
        width: 100%;
        padding-top: 7.8125vw;
        box-sizing: border-box;
        .vvw(padding,100,60,0);
        .banner1_main{
            display: flex;
            flex-direction: column;
            width: 100%; padding-top: 2.6145833vw;
            .text2{
                width: 100%; padding-right: 0;
                margin-left: 0;
                .vvw(margin-top,40);
                .vvw(font-size,24);
                color: #6C7388;
                line-height: 5.1875vw;
                box-sizing: border-box;
            }
            .banner1{
                display: none;
            }
            .banner2{
                width: 100%;
                display: block;
            }
        }
    }
    .part2{
        width: 100%; height: 100%;
        margin: 10.8125vw 0 0 0;
        padding-top: 0vw;
        .box_h_side_c{
            .vvw(padding,0,110);
            .vvw(font-size,24);
            .item{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        .info{
            .vvw(font-size,24);
            width: 100%;
            .vvw(padding,0,110);
            margin-top: 2.0604166vw;
            line-height: 5.1875vw;
        }
    }
    .part3{
        box-sizing: border-box;
        .vvw(padding,80,60,0);
        .part3_body{
            padding:5.00625vw 0;
        }
        .item1,
        .item2{
            .vvw(font-size,24);
            width: 43.395833vw;
            padding: 2.03125vw;
            line-height: 4.6666vw;
        }
        .item2,.item3{
            width: 100%;
        }
        .title1{
            .vvw(font-size,32);
            padding-bottom: 1.354166vw;
        }
    }
    .part4{
        .part4_main{
            width: 100%;
            padding: 6.25vw 0;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .item{
                width: 43.04375vw; height: 67.5625vw;
                p{
                .vvw(font-size,24);
                .vvw(line-height,40);
                    width: 20.8125vw;
                }
            }
        }
    }
    .part5{
        .part5_body{
            width: 100%; height: 35.59375vw;
            position: relative;
            div{
                .vvw(font-size,24);
                .vvw(line-height,50);
                width: 20.791666vw; height: 21.2604167vw;
                padding-top: 5.0104166vw;
            }
            .item1{
                left:0;
            }
            .item2{
                left:17.7604vw;
            }
            .item3{
                left:33.9895833vw;
            }
            .item4{
                left:50.010416vw;
            }
            .item5{
                left:67.16666vw;
            }
        }
    }
    .part6{
        .part6_body{
            padding: 1.09166vw 0 0 0;
            width: 100%;
            height: 100%;
            flex-direction: column;
            .text3{
                .vvw(font-size,24);
                width: 100%; height: 100%;
                line-height: 5.1875vw;
            }
        }
        .quan{
            width: 85.729166vw; height: 85.729166vw;
            position: relative;
            .vvw(top,50);
            .vvw(left,0);
            img{
                width: 5.5vw;
                opacity: 0;
            }
            .icon1{
                position: absolute; left:44.5625vw; top:6.0675vw;
                animation:animateFn2 2s linear .2s infinite;
            }
            .icon2{
                position: absolute; left:64.50625vw; top:13.28125vw;
                transform: rotate(30deg);
                animation:animateFn2 2s linear .4s infinite;
            }
            .icon3{
                position: absolute; left:74.3125vw; top:34.21875vw;
                transform: rotate(70deg);
                animation:animateFn2 2s linear .6s infinite;
            }
            .icon4{
                position: absolute; left:71.96875vw; top:57.5395833vw;
                transform: rotate(100deg);
                animation:animateFn2 2s linear .8s infinite;
            }
            .icon5{
                position: absolute; left:56.3333vw; top:72.833333vw;
                transform: rotate(130deg);
                animation:animateFn2 2s linear 1s infinite;
            }
            .icon6{
                position: absolute; left:35.38958333vw; top:76.3958333vw;
                transform: rotate(170deg);
                animation:animateFn2 2s linear 1.2s infinite;
            }
            .icon7{
                position: absolute; left:15.375vw; top:67.916666vw;
                transform: rotate(210deg);
                animation:animateFn2 2s linear 1.4s infinite;
            }
            .icon8{
                position: absolute; left:5.4541666vw; top:47.9875vw;
                transform: rotate(244deg);
                animation:animateFn2 2s linear 1.6s infinite;
            }
            .icon9{
                position: absolute; left:9.104166vw; top:25.8vw;
                transform: rotate(285deg);
                animation:animateFn2 2s linear 1.8s infinite;
            }
            .icon10{
                position: absolute; left:22.40625vw; top:9.9375vw;
                transform: rotate(310deg);
                animation:animateFn2 2s linear 2s infinite;
            }
        }
        .nquan{
            position: relative;
            width: 40.1770833vw; height: 40.1770833vw;
            background: url('../assets/solution/arc_icon1.png') no-repeat center center;
            background-size: 100% 100%;
        }
        .nquan_icon1{
            width: 10.28125vw; height: 24.0395833vw;
            position: absolute; right: -6.68125vw; top: 1.7479166vw;
        }
        .nquan_icon2{
            width: 10.28125vw; height: 24.0395833vw;
            position: absolute; left: -7.67708333vw; top: 4.66875vw;
        }
    }
    .part7{
        .vvw(margin-top,100);
        .part7_body{
            width: 100%;
            height: 100%;
            margin: 8.6666vw 0 0 0;
            background: url(' ') no-repeat left center;
            // padding-left: 0;
            // padding-right: 0;
            .item{
                .vvw(width,700);
                height: 20.1041667vw;
                .btn1{
                    .vvw(font-size,32);
                    width: 14.15625vw; height: 14.15625vw;
                    border-radius: 1vw;
                    position: absolute; left: -9.291666vw; top: 30%;
                    margin-top: -3.1041666vw;
                    &:after{
                        content: '';
                        width: 3.979166vw; height: 5.3854166vw;
                        position: absolute; right: -5.166666vw; top: 45%;
                        margin-top: -1.66666vw;
                    }
                }
                .mb{
                    width: 38.645833vw; height: 100%;
                    background: url('../assets/solution/part_7mb.png') no-repeat center center;
                    background-size: 100% 100%;
                    padding-right: 4.729166vw; box-sizing: border-box;
                    a{
                       .vvw(font-size,24);
                        display: block;
                        width: 20.025vw; height: 5.63125vw;
                        border-radius: 5.04166vw;
                        line-height: 5.63125vw;
                    }
                }
            }
        }
    }
    .part8{
        .vvw(padding,80,60,0);
        .part8_body{
            .box_h_space_c{
                .item{
                    &:hover{
                        border: 2px solid #BDE3FF;
                    }
                }
            }
        }
    }
    .part9{
        .part9_body{
            width: 100%; height:100%; margin: 3.6458333vw 0 13.020833vw 0;
            box-sizing: border-box;
            background: url('') no-repeat right center;
            background-size: auto 100%;
            .title1{
                padding-top: 2.1979166vw;
                padding-bottom: 5.5791666vw;
                .vvw(font-size,40);
                font-weight: 800;
                color: #00538D;
            }
            .info{
                width: 100%; margin: 0 auto;
                display: flex;
                flex-direction: column;
            }
            .left{
                width: 100%;
                div{
                    .vvw(font-size,24);
                    box-sizing: border-box;
                    width: 100%; background: rgba(0, 132, 224, .23);
                    border-radius: 1.04166vw; margin-bottom: 4.73125vw;
                    padding: 6.304166vw 3.1979166vw 3.9020833vw 3.1979166vw; position: relative;
                }
                p{
                    .vvw(font-size,24);
                    position: absolute; left:50%; margin-left: -17.6875vw;
                    top: -2.25vw;
                    width: 38.3229vw;
                    height: 5.4479vw;
                    line-height: 4.8229166vw;
                }
                span{
                    display: block;
                    line-height: 4.3229166vw;
                    .vvw(font-size,24);;
                    color: #00538D;
                }
            }
            .moreBox{
                width: 100%;
             .center{
                div{
                    width: 15.2666vw;
                    height: 15.2666vw;
                    margin-bottom: 1.70208vw;
                }
                p{
                    line-height: 4.4229166vw;
                    .vvw(font-size,24);;
                }
            }
            .right{
                flex: 1;
                div{
                    padding: 0.88979166vw 2vw;
                    width: 100%;
                    height: 15.2666vw;
                    margin-bottom: 1.7020833vw;
                    box-sizing: border-box;
                }
                p{
                    .vvw(font-size,24);;
                    line-height: 4.32291667vw;
                }
            }
         }
        }
    }
    .part10{
        .part10_body{
            width: 100%; margin: 0 auto;
            box-sizing: border-box;
            padding: 10.3229166vw 0 7.1875vw 0;
            .title1{
                .vvw(font-size,24);;
                font-weight: 400;
                color: #00538D;
                line-height: 5.71875vw;
                padding-bottom: 3.5625vw;
            }
            .box_h_side_t{
                display: flex;
                flex-direction: column;
            .item{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .vvw(margin-top,100);
                div{
                    width: 60%; height: 30.7291667vw;
                    background: rgba(0, 132, 224, .2);
                    border-radius: 5.71875vw;
                    margin-bottom: 1.625vw;
                    img{
                        .vvw(width,400)!important;
                        .vvw(height,200)!important;
                    }
                }
                p{
                    width: 60%; height: 6.3333vw;
                    margin-bottom: 2.8354166vw;
                    text-align: center;
                    line-height: 6.3333vw;
                    background: rgba(0, 132, 224, .2);
                    border-radius: 2.71875vw;
                    .vvw(font-size,24);;
                    font-weight: 800;
                    color: #00538D;
                }
                span{
                    .vvw(font-size,24);;
                    color: #6F768B;
                    line-height: 5.71875vw;
                }
            }
            .item:nth-last-child(1){
                div{
                    img{
                      .vvw(width,280)!important;
                      .vvw(height,280)!important;
                    }
                }
            }
            }
        }
    }
    .el-carousel .el-carousel__indicators{
        display: none!important;
    }
  }
</style>
